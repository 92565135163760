/** @jsx jsx */
import { jsx, css, Global } from "@emotion/react"


const variables = css`
  :root {
    --nav-menu-display: none;
  }

  @media screen and (min-width: 64rem) {
    :root {
      --nav-menu-display: inline;
    }
  }
`

const styles = css`
  &[data-post-nav-menu] {
    order: 1;
    top: 20%;
    margin-top: 1rem;
    z-index: 2;
    left: 100%;
    display: var(--nav-menu-display);
    position: sticky;
    align-self: flex-start;
  }
`


interface Props {
  navs: [string,string][]
}

const NavMenu: FC<Props> = ({ navs }) => (
  <nav css={styles} data-section="inline" data-post-nav-menu>
    <Global styles={variables} />
    <ul>
      {navs.map(({ 0: id, 1: text }) => (
        <li key={`${id}-heading-list-item`}>
          <a href={`#${id}`}>{text}</a>
        </li>
      ))}
    </ul>
  </nav>
)


export default NavMenu
