import unified from 'unified'
import parse from 'remark-parse'
import remark2react from 'remark-react'
import headingIds from "remark-heading-id"

interface Props {
  note: string
  headings: [string,string][]
}

const CollectedNoteMD: FC<Props> = ({ note, headings }) => {
  const results = [...note.matchAll(/#{1,4}\s.*/g)]
  let noteWithHeadingIds = String(note)
  results.map(({ 0: result }, index) => {
    const { 0: id } = headings[index]
    noteWithHeadingIds = noteWithHeadingIds.replace(result, `${result} {#${id}}`)
  })


  const { result } =
    unified()
      .use(headingIds)
      .use(parse)
      .use(remark2react, {
        sanitize: {
          clobberPrefix:""
        }
      })
      .processSync(noteWithHeadingIds)

  return (
    <article>
      {result}
    </article>
  )
}


export default CollectedNoteMD
