/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import Base from "layouts/Base"
import Hero from "components/Hero"
import NavMenu from "components/NavMenu"
import Section from "blocks/Section"
import CollectedNoteMD from "blocks/Post/CollectedNoteMD"


const styles = css`
  &[data-post] {
    display: flex;
    position: relative;
  }
`

interface BuildProps {
  note: string
  navMenu: boolean
  frontmatter: FrontMatter
}

const Post: TPFC<BuildProps> = ({ pageContext: { data } }) => {

  return (
    <Base>
      <Hero
        img={{
          alt: data.frontmatter.heroImgAlt,
          src: data.frontmatter.heroImgSrc,
        }}
      />
      <Section css={styles} data-post>
        <NavMenu navs={data.frontmatter.headings} />
        <CollectedNoteMD note={data.note} headings={data.frontmatter.headings}/>
      </Section>
    </Base>
  )
}



export default Post
