/** @jsx jsx */
import { jsx, css, Global } from "@emotion/react"

const variables = css`
  :root {
    --hero-height: 20rem;
    --hero-text-height: 10rem;
    --hero-font-size: 3rem;
  }

  @media screen and (min-width: 48rem) {
    :root {
      --hero-height: 29.375rem;
      --hero-text-height: 15rem;
      --hero-font-size: 6rem;
    }
  }

  @media screen and (min-width: 64rem) {
    :root {
      --hero-text-height: 20rem;
      --hero-font-size: 8rem;
    }
  }

  @media screen and (min-width: 80rem) {
    :root {
      --hero-text-height: 25rem;
      --hero-font-size: 10rem;
    }
  }

  @media screen and (min-width: 90rem) {
    :root {
      --hero-height: 75vh;
    }
  }

  [data-prefers="dark"] [data-hero-text] {
    color: var(--black);
    background-color: var(--white);
  }
`

const styles = css`
  &[data-hero-header] {
    z-index: 3;
    position: relative;
    height: var(--hero-height);
    background-color: var(--black);
  }

  [data-hero-image-container] {
    margin: 0;
    height: 100%;
  }

  [data-hero-image] {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &[data-hero-header="text"] {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100vw;
    font-size: var(--hero-font-size);
  }

  [data-hero-text] {
    display: flex;
    max-width: unset;
    height: var(--hero-text-height);
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`

interface Props {
  img?: {
    src: string
    alt: string
  }
}

const Hero: FC<Props> = ({ img }) => {
  return (
    <header css={styles} data-hero-header>
      <Global styles={variables}/>
      <figure data-hero-image-container>
        <img data-hero-image src={img.src} alt={img.alt} />
      </figure>
    </header>
  )
}

export default Hero
